import React from "react";
import { Link } from "react-router-dom";

import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";

// Inline CSS, copied and adapted from the Home component (you can move this to a separate CSS file)
const styles = `
  .buy-sec {
    padding: 20px 0;
    background: url('https://www.transparenttextures.com/patterns/45-degree-fabric-light.png');
    background-size: 100px 100px;
  }
  .steps-grid {
    padding: 10px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .step-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
  }
  .step-card__image {
    width: 100%;
    max-width: 400px;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }
  .step-card__description {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-top: 5px;
    text-align: center;
  }
`;

class HowToBuy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayTab: "hometab",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    // Define the steps data with renamed image paths
    const steps = [
      {
        description: "Browse and select your desired products from Flyorship.",
        image: "/assets/images/1_select.png",
      },
      {
        description: "Add your selected items to the cart.",
        image: "/assets/images/2_cart.png",
      },
      {
        description: "Provide your delivery address for shipping.",
        image: "/assets/images/3_address.png",
      },
      {
        description: "Pay securely in Naira using your preferred method.",
        image: "/assets/images/4_pay.png",
      },
      {
        description: "Your order will be processed and shipped to Nigeria.",
        image: "/assets/images/5_delivery.png",
      },
      {
        description: "Enjoy fast and reliable delivery to your address.",
        image: "/assets/images/6_receive.png",
      },
    ];

    return (
      <React.Fragment>
        {/* Inject the styles */}
        <style>{styles}</style>

        <div className="">
          <div className="top-content buy-bg">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-heading">
                    <h1>How to Buy on Flyorship</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="buy-sec">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="tab-sec">
                    <div className="tab-content">
                      <div className="tab-pane active" id="hometab">
                        <div className="steps-grid">
                          <div className="row gx-3">
                            {steps.map((step, index) => (
                              <div
                                className="col-lg-4 col-md-4 col-sm-6"
                                key={index}
                              >
                                <div className="step-card">
                                  <img
                                    src={step.image}
                                    alt={step.description}
                                    className="step-card__image"
                                    onError={(e) => {
                                      e.target.src =
                                        "https://via.placeholder.com/400?text=Image+Not+Found";
                                    }}
                                  />
                                  <div className="step-card__description">
                                    {step.description}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HowToBuy;
